import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API_REQUEST } from '../helpers/request';
import UploadModal from '../components/quote/uploadModal';
import { Plus, Upload } from 'lucide-react';
import ProjectItem from '../components/dashboard/ProjectItem';

const EmptyState = ({ onUploadClick }) => (
  <div className="empty-state-container">
    <div className="empty-state">
      <div className="upload-icon">
        <Upload size={48} strokeWidth={1} />
      </div>
      <h3>You don&apos;t have any quotes yet in this project</h3>
      <p>Upload up to 3 documents today</p>
      <button onClick={onUploadClick} className="upload-button">
        UPLOAD QUOTES NOW
      </button>
    </div>
  </div>
);

EmptyState.propTypes = {
  onUploadClick: PropTypes.func.isRequired,
};

export default function DashboardPage() {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await API_REQUEST.get('/projects');
        if (API_REQUEST.isSuccess(response.status)) {
          setProjects(response.data);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleProjectDelete = (projectId) => {
    setProjects((currentProjects) =>
      currentProjects.filter((project) => project.id !== projectId)
    );
  };

  const handleAddPackage = (projectId, packages) => {
    setSelectedProjectId(Number(projectId));
    setSelectedPackages(packages);
    setShowUploadModal(true);
  };

  const handleCloseModal = () => {
    setShowUploadModal(false);
    setSelectedProjectId(null);
    setSelectedPackages([]);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <div className="header-content">
          <h1>Projects</h1>
          <p>
            You have {projects.length} project{projects.length !== 1 ? 's' : ''}
          </p>
        </div>
        <button
          onClick={() => {
            setSelectedProjectId(null);
            setSelectedPackages([]);
            setShowUploadModal(true);
          }}
          className="new-button"
        >
          <Plus className="plus-icon" />
          <span>New</span>
        </button>
      </div>

      {isLoading ? (
        <div className="loading-state">
          <div className="spinner"></div>
          <p>Loading projects...</p>
        </div>
      ) : projects.length === 0 ? (
        <EmptyState
          onUploadClick={() => {
            setSelectedProjectId(null);
            setSelectedPackages([]);
            setShowUploadModal(true);
          }}
        />
      ) : (
        <div className="projects-list">
          {projects.map((project) => (
            <ProjectItem
              key={project.id}
              project={project}
              onAddPackage={handleAddPackage}
              onProjectDelete={handleProjectDelete}
            />
          ))}
        </div>
      )}

      <UploadModal
        show={showUploadModal}
        setShow={handleCloseModal}
        existingProjectId={selectedProjectId}
        existingPackages={selectedPackages}
      />
    </div>
  );
}
