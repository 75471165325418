import React from 'react';
import { Modal } from 'react-bootstrap';
import { Trash2 } from 'lucide-react';
import PropTypes from 'prop-types';

const DeleteConfirmationModal = ({
  show,
  onClose,
  onConfirm,
  itemType,
  itemName,
}) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      className="modern-delete-modal"
    >
      <Modal.Body>
        <div className="trash-icon-container">
          <Trash2 />
        </div>
        <h3>Delete {itemType}</h3>
        <p>
          Are you sure you want to delete {itemName}? This action cannot be
          undone.
        </p>
        <div className="modal-actions">
          <button onClick={onClose} className="btn-cancel">
            Cancel
          </button>
          <button onClick={onConfirm} className="btn-delete">
            Delete {itemType}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  itemType: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
};

export default DeleteConfirmationModal;
