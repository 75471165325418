import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_REQUEST } from '../../helpers/request';
import { ChevronDown, ChevronRight, Package, Plus, Trash2 } from 'lucide-react';
import PropTypes from 'prop-types';
import DeleteConfirmationModal from './DeleteConfirmationModal';

import { Button } from 'react-bootstrap';
const STATUS_COLORS = {
  PENDING: '#FDB022', // Yellow
  STARTED: '#FDB022', // Yellow
  FAILURE: '#F04438', // Red
  SUCCESS: '#12B76A', // Green
  UNPROCESSABLE: '#F04438', // Red
};

const ProjectItem = ({ project, onAddPackage, onProjectDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packageDetails, setPackageDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingQuotes, setIsLoadingQuotes] = useState({});
  const [tenderStatus, setTenderStatus] = useState({});
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    type: null,
    itemId: null,
    itemName: '',
    affectedItems: [],
  });

  // Add new function to fetch tender analysis status
  const fetchTenderAnalysis = async (packageId) => {
    try {
      const response = await API_REQUEST.get(
        `/projects/${project.id}/packages/${packageId}/tender_analysis`
      );
      if (API_REQUEST.isSuccess(response.status)) {
        setTenderStatus((prev) => ({
          ...prev,
          [packageId]: response.data.status,
        }));
      }
    } catch (error) {
      console.error('Error fetching tender analysis:', error);
      setTenderStatus((prev) => ({
        ...prev,
        [packageId]: 'FAILURE',
      }));
    }
  };

  const fetchPackageDetails = async (packageId) => {
    if (isLoadingQuotes[packageId]) return;

    setIsLoadingQuotes((prev) => ({ ...prev, [packageId]: true }));
    try {
      const response = await API_REQUEST.get(
        `/projects/${project.id}/packages/${packageId}/quotes`
      );
      if (API_REQUEST.isSuccess(response.status)) {
        const quotes = response.data;
        const uniqueSubcontractors = new Set(
          quotes.map((quote) => quote.subcontractor_name)
        );
        setPackageDetails((prev) => ({
          ...prev,
          [packageId]: {
            subcontractorCount: uniqueSubcontractors.size,
          },
        }));
      }
      await fetchTenderAnalysis(packageId);
    } catch (error) {
      console.error('Error fetching package details:', error);
    } finally {
      setIsLoadingQuotes((prev) => ({ ...prev, [packageId]: false }));
    }
  };
  const fetchPackages = async () => {
    try {
      setIsLoading(true);
      const response = await API_REQUEST.get(
        `/projects/${project.id}/packages`
      );
      if (API_REQUEST.isSuccess(response.status)) {
        setPackages(response.data);
      }
    } catch (error) {
      console.error('Error fetching packages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, [project.id]);

  useEffect(() => {
    // Fetch quotes for all packages when project is expanded
    if (isExpanded && packages.length > 0) {
      packages.forEach((pck) => {
        // Only fetch if we don't have the details yet
        if (!packageDetails[pck.id]) {
          fetchPackageDetails(pck.id);
        }
      });
    }
  }, [isExpanded, packages]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handlePackageClick = (packageId) => {
    navigate(`/dashboard/project/${project.id}/package/${packageId}/result`);
  };

  const handleProjectDelete = async () => {
    try {
      const response = await API_REQUEST.delete(`/projects/${project.id}`);
      if (API_REQUEST.isSuccess(response.status)) {
        onProjectDelete(project.id);
        setDeleteModal({
          show: false,
          type: null,
          itemId: null,
          itemName: '',
          affectedItems: [],
        });
      }
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const handlePackageDelete = async (packageId) => {
    try {
      const response = await API_REQUEST.delete(
        `/projects/${project.id}/packages/${packageId}`
      );
      if (API_REQUEST.isSuccess(response.status)) {
        setPackages(packages.filter((p) => p.id !== packageId));
        setDeleteModal({
          show: false,
          type: null,
          itemId: null,
          itemName: '',
          affectedItems: [],
        });
      }
    } catch (error) {
      console.error('Error deleting package:', error);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
  };

  return (
    <div className="project-item">
      <div className="project-header" onClick={handleToggle}>
        <div className="project-title">
          {isExpanded ? (
            <ChevronDown className="chevron-icon" />
          ) : (
            <ChevronRight className="chevron-icon" />
          )}
          <span className="project-name">{project.name}</span>
          <span className="package-count">
            {packages.length} Package{packages.length !== 1 ? 's' : ''}
          </span>
        </div>
        <div className="actions">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onAddPackage(project.id, packages);
            }}
            className="add-package-button"
          >
            <Plus className="plus-icon" />
            <span>Add Package</span>
          </button>

          <Button
            variant="link"
            className="delete-btn"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModal({
                show: true,
                type: 'project',
                itemId: project.id,
                itemName: project.name,
                affectedItems: [
                  `${packages.length} package${packages.length !== 1 ? 's' : ''}`,
                ],
              });
            }}
          >
            <Trash2 size={20} />
          </Button>
        </div>
      </div>

      <div className={`project-content ${isExpanded ? 'expanded' : ''}`}>
        {isLoading ? (
          <div className="loading">Loading packages...</div>
        ) : packages.length > 0 ? (
          <div className="packages-list">
            {packages.map((pck) => (
              <div
                key={pck.id}
                className="package-item"
                onClick={() => handlePackageClick(pck.id)}
              >
                <div className="package-info">
                  <Package className="package-icon" />
                  <div className="package-details">
                    <h4>{pck.name}</h4>
                    <div className="package-meta">
                      <span>{formatDate(pck.created)}</span>
                    </div>
                  </div>
                </div>
                <div className="package-metrics d-flex align-items-center gap-4">
                  <div className="status-indicator">
                    <span className="duration">
                      {packageDetails[pck.id]?.subcontractorCount || 0}{' '}
                      subcontractor
                      {(packageDetails[pck.id]?.subcontractorCount || 0) !== 1
                        ? 's'
                        : ''}
                    </span>
                    {tenderStatus[pck.id] && (
                      <span
                        className="status-circle"
                        style={{
                          backgroundColor: STATUS_COLORS[tenderStatus[pck.id]],
                        }}
                      />
                    )}
                  </div>
                  <Button
                    variant="link"
                    className="text-secondary p-2 rounded-circle delete-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteModal({
                        show: true,
                        type: 'package',
                        itemId: pck.id,
                        itemName: pck.name,
                        affectedItems: [],
                      });
                    }}
                  >
                    <Trash2 size={20} />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-packages">No packages found in this project</div>
        )}
      </div>

      <DeleteConfirmationModal
        show={deleteModal.show}
        onClose={() =>
          setDeleteModal({
            show: false,
            type: null,
            itemId: null,
            itemName: '',
            affectedItems: [],
          })
        }
        onConfirm={() => {
          if (deleteModal.type === 'project') {
            handleProjectDelete();
          } else if (deleteModal.type === 'package') {
            handlePackageDelete(deleteModal.itemId);
          }
        }}
        itemType={deleteModal.type}
        itemName={deleteModal.itemName}
        affectedItems={deleteModal.affectedItems}
      />
    </div>
  );
};

ProjectItem.propTypes = {
  project: PropTypes.object.isRequired,
  onAddPackage: PropTypes.func.isRequired,
  onProjectDelete: PropTypes.func.isRequired,
};

export default ProjectItem;
