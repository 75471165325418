import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BackButton from '../components/BackButton';
import styles from '../components/muiStyles';
import { proqouImages } from '../constants/index';

const TermsAndConditions = () => {
  const terms = [
    {
      title: 'Introduction',
      content:
        'Welcome to SmartTenderAI, a service provided by Construction Link ("C-Link," "we," "our," or "us"). By accessing or using SmartTenderAI ("the Service"), you agree to these terms and conditions ("Terms"). If you do not agree, please do not use the Service.',
    },
    {
      title: 'Scope of Service',
      content:
        'SmartTenderAI provides insights and recommendations based on tender data. The Service is intended to assist Quantity Surveyors and other professionals in decision-making. The insights are based on the data you provide and are not a substitute for professional judgment.',
    },
    {
      title: 'User Responsibilities',
      content:
        'You are responsible for ensuring the accuracy, legality, and completeness of any data uploaded to the Service. You agree to use the Service in compliance with all applicable laws and regulations.',
    },
    {
      title: 'Data Usage and Privacy',
      content:
        'You retain ownership of the data you upload to the Service. By using the Service, you grant C-Link a license to process and analyse the data solely to provide the Service. Your data will be handled in accordance with our Privacy Policy.',
    },
    {
      title: 'Intellectual Property',
      content:
        'C-Link or its licensors own all intellectual property rights in SmartTenderAI, including software, trademarks, and content. You may not copy, modify, distribute, or reverse engineer any part of the Service without prior written consent.',
    },
    {
      title: 'Limitations of Liability',
      content:
        'The Service is provided "as is" without any warranties, express or implied. C-Link is not liable for any indirect, incidental, or consequential damages arising from your use of the Service.',
    },
    {
      title: 'Fees and Payment',
      content:
        'Access to certain features of the Service may change and require subscription fees to be paid. Details of fees will be provided with reasonable notice or at the time of subscription. Payments are due in advance and are non-refundable, except as required by law.',
    },
    {
      title: 'Termination',
      content:
        'You may stop using the Service at any time. We reserve the right to terminate or suspend your access to the Service for violation of these Terms or at our discretion with reasonable notice.',
    },
    {
      title: 'Modifications',
      content:
        'We may update these Terms from time to time. Any changes will be communicated, and your continued use of the Service after changes are made constitutes acceptance of the updated Terms.',
    },
  ];

  return (
    <Box sx={styles.page}>
      <Box sx={styles.helpSupport.back}>
        <BackButton />
      </Box>

      <Grid container sx={styles.limitWidth}>
        <Grid item xs={12} sx={styles.navigation}>
          <Box sx={styles.navigation.logo}>
            <Typography sx={styles.navigation.logo.text}>
              <img
                src={`${proqouImages}/ai-round-logo.svg`}
                alt="Smart Tender AI"
                style={styles.navigation.logo.roundLogo}
              />
              Smart Tender AI <br />
              <img
                src={`${proqouImages}/c-link-powered.png`}
                alt="Smart Tender AI"
                style={styles.navigation.logo.powered}
              />
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sx={styles.helpSupport.container}>
          <Typography sx={styles.helpSupport.heading}>
            Terms and Conditions
          </Typography>

          <Box>
            {terms.map((term, index) => (
              <Box key={index} className="term-item" sx={{ mb: 4 }}>
                <Typography sx={styles.helpSupport.subtitle}>
                  {term.title}
                </Typography>
                <Typography sx={styles.helpSupport.paragraph}>
                  {term.content}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box sx={{ mt: 6 }}>
            <Typography sx={styles.helpSupport.title}>Contact</Typography>
            <Typography sx={styles.helpSupport.paragraph}>
              For any questions or concerns regarding these Terms, please
              contact us at:
            </Typography>
            <Typography sx={styles.helpSupport.paragraph}>
              Construction Link Limited (Company Number: 09791821)
              <br />
              Registered at: 85 Great Portland Street, First Floor
              <br />
              London, United Kingdom, W1W 7LT
              <br />
              Email: support@c-link.com
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TermsAndConditions;
