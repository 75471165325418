import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(-1)}
      aria-label="Go back to previous page"
      className="back-button"
    >
      <ArrowLeft className="h-5 w-5" strokeWidth={1.5} />
    </button>
  );
};

export default BackButton;
